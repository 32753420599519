@import url("./components/assets/vendor/fontawesome-free/css/all.min.css");
@import url("./components/assets/vendor/animate.css/animate.min.css");
@import url("./components/assets/vendor/bootstrap-icons/bootstrap-icons.css");
@import url("./components/assets/vendor/boxicons/css/boxicons.min.css");
@import url("./components/assets/vendor/glightbox/css/glightbox.min.css");
@import url("./components/assets/vendor/remixicon/remixicon.css");
@import url("./components/assets/vendor/swiper/swiper-bundle.min.css");
@import url("./components/assets/css/style.css");

/* Second Template CSS */
@import url("./assets/vendor/bootstrap-icons/bootstrap-icons.css");
@import url("./assets/vendor/boxicons/css/boxicons.min.css");
@import url("./assets/vendor/quill/quill.snow.css");
@import url("./assets/vendor/quill/quill.bubble.css");
@import url("./assets/vendor/remixicon/remixicon.css");
@import url("./assets/vendor/simple-datatables/style.css");
@import url("./assets/css/style.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");

.marquee {
	/* width: 100%; */
	overflow: hidden;
	white-space: nowrap;
}

.content {
	display: inline-block;
	animation: marquee 60s linear infinite;
}

.paused {
	animation-play-state: paused;
}

@keyframes marquee {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}
