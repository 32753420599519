/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
	font-family: "Open Sans", sans-serif;
	color: #444444;
}

a {
	color: #d79da9;
	text-decoration: none;
}

a:hover {
	color: #e57e80;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #fff;
}

#preloader:before {
	content: "";
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #d79da9;
	border-top-color: #d1e6f9;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	-webkit-animation: animate-preloader 1s linear infinite;
	animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	right: 15px;
	bottom: 15px;
	z-index: 996;
	background: rgb(44, 73, 100);
	width: 40px;
	height: 40px;
	border-radius: 4px;
	transition: all 0.4s;
}

.back-to-top i {
	font-size: 28px;
	color: #fff;
	line-height: 0;
}

.back-to-top:hover {
	background: #e57e80;
	color: #fff;
}

.back-to-top.active {
	visibility: visible;
	opacity: 1;
}

.datepicker-dropdown {
	padding: 20px !important;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
	background: #fff;
	height: 40px;
	font-size: 12px;
	transition: all 0.5s;
	z-index: 996;
}

#topbar.topbar-scrolled {
	top: -40px;
}

#topbar .contact-info a {
	line-height: 1;
	color: #2c4964;
	transition: 0.3s;
}
.colorphone {
	color: #2c4964;
}

#topbar .contact-info a:hover {
	color: #e77d7d;
}

#topbar .contact-info i {
	color: #d79da9;
	padding-right: 4px;
	margin-left: 15px;
	line-height: 0;
}

#topbar .contact-info i:first-child {
	margin-left: 0;
}

#topbar .social-links a {
	color: rgb(44, 73, 100);
	padding-left: 15px;
	display: inline-block;
	line-height: 1px;
	transition: 0.3s;
}

#topbar .social-links a:hover {
	color: #e57e80;
}

#topbar .social-links a:first-child {
	border-left: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
	background: #fff;
	transition: all 0.5s;
	z-index: 997;
	padding: 15px 0;
	top: 40px;
	box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
}

#header.header-scrolled {
	top: 0;
}

#header .logo {
	font-size: 30px;
	margin: 0;
	padding: 0;
	line-height: 1;
	font-weight: 700;
	letter-spacing: 0.5px;
	font-family: "Poppins", sans-serif;
}

#header .logo a {
	color: #e37e82;
}

#header .logo img {
	max-height: 40px;
}

/**
* Appointment Button *
*/
#form1 {
	position: relative;
}
#form2 {
	display: none;
	position: relative;
}
#form3 {
	display: none;
	position: relative;
}
.appointment-btn {
	margin-left: 25px;
	background: rgb(44, 73, 100);
	color: #fff;
	border-radius: 50px;
	padding: 8px 25px;
	white-space: nowrap;
	transition: 0.3s;
	font-size: 14px;
	display: inline-block;
}

.appointment-btn:hover {
	background: #e57e80;
	color: #fff;
}

@media (max-width: 768px) {
	.appointment-btn {
		margin: 0 15px 0 0;
		padding: 6px 18px;
	}
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
	padding: 0;
}

.navbar ul {
	margin: 0;
	padding: 0;
	display: flex;
	list-style: none;
	align-items: center;
}

.navbar li {
	position: relative;
}

.navbar > ul > li {
	position: relative;
	white-space: nowrap;
	padding: 8px 0 8px 20px;
}

.navbar a,
.navbar a:focus {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #2c4964;
	white-space: nowrap;
	transition: 0.3s;
	border-bottom: 2px solid #fff;
	padding: 5px 2px;
}

.navbar a i,
.navbar a:focus i {
	font-size: 12px;
	line-height: 0;
	margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
	color: #d79da9;
	border-color: #11193d;
}

.navbar .dropdown ul {
	display: block;
	position: absolute;
	left: 20px;
	top: calc(100% + 30px);
	margin: 0;
	padding: 10px 0;
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
	transition: 0.3s;
}

.navbar .dropdown ul li {
	min-width: 200px;
}

.navbar .dropdown ul a {
	padding: 10px 20px;
	font-size: 14px;
	font-weight: 500;
	text-transform: none;
	color: #082744;
	border: none;
}

.navbar .dropdown ul a i {
	font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
	color: #d79da9;
}

.navbar .dropdown:hover > ul {
	opacity: 1;
	top: 100%;
	visibility: visible;
}

.navbar .dropdown .dropdown ul {
	top: 0;
	left: calc(100% - 30px);
	visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
	opacity: 1;
	top: 0;
	left: 100%;
	visibility: visible;
}

@media (max-width: 1366px) {
	.navbar .dropdown .dropdown ul {
		left: -90%;
	}

	.navbar .dropdown .dropdown:hover > ul {
		left: -100%;
	}
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
	color: #2c4964;
	font-size: 28px;
	cursor: pointer;
	display: none;
	line-height: 0;
	transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
	color: #fff;
}

@media (max-width: 991px) {
	.mobile-nav-toggle {
		display: block;
	}

	.navbar ul {
		display: none;
	}
}

.navbar-mobile {
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(28, 47, 65, 0.9);
	transition: 0.3s;
	z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
	position: absolute;
	top: 15px;
	right: 15px;
}

.navbar-mobile ul {
	display: block;
	position: absolute;
	top: 55px;
	right: 15px;
	bottom: 15px;
	left: 15px;
	padding: 10px 0;
	background-color: #fff;
	overflow-y: auto;
	transition: 0.3s;
}

.navbar-mobile > ul > li {
	padding: 0;
}

.navbar-mobile a,
.navbar-mobile a:focus {
	padding: 10px 20px;
	font-size: 15px;
	color: #2c4964;
	border: none;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
	color: #d79da9;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
	margin: 15px;
}

.navbar-mobile .dropdown ul {
	position: static;
	display: none;
	margin: 10px 20px;
	padding: 10px 0;
	z-index: 99;
	opacity: 1;
	visibility: visible;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
	min-width: 200px;
}

.navbar-mobile .dropdown ul a {
	padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
	font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
	color: #d79da9;
}

.navbar-mobile .dropdown > .dropdown-active {
	display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#home {
	width: 100%;
	height: 90vh;
	background: url("../img/ayurved.jpg") top center;
	background-size: cover;
	/* margin-bottom: -200px; */
}

#home .container {
	position: relative;
	margin-top: 130px;
}
.ayurvedimage {
	width: 100px;
	height: 100px;
	/* margin-left: 18%; */
	border-radius: 50%;
}

#home h1 {
	/* margin-top: 30px ; */
	font-size: 48px;
	font-weight: 700;
	line-height: 88px;
	text-transform: uppercase;
	color: #2c4964;
	background: #1b1b1b -webkit-linear-gradient(
			right,
			#09ebeb,
			#e75309,
			#2c03e4,
			#dc0aa4
		) no-repeat 00/80px;
	-webkit-text-fill-color: rgba(43, 13, 237, 0.2);
	background-clip: text;
	animation: LightSweep 3s ease-in infinite;
}
@keyframes LightSweep {
	0% {
		background-position: 0%;
	}
	100% {
		background-position: 100%;
	}
}

#home h2 {
	color: #2c4964;
	margin: 10px 0 0 0;
	font-size: 24px;
}

#home .btn-get-started {
	font-family: "Raleway", sans-serif;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 12px 35px;
	margin-top: 30px;
	border-radius: 50px;
	transition: 0.5s;
	color: #fff;
	background: rgb(44, 73, 100);
}

#home .btn-get-started:hover {
	background: #e57e80;
}

@media (min-width: 1024px) {
	#home {
		background-attachment: fixed;
	}
}

@media (max-width: 992px) {
	#home {
		margin-bottom: 0;
		height: 100vh;
	}

	#home .container {
		padding-bottom: 63px;
	}

	#home h1 {
		font-size: 28px;
		line-height: 36px;
	}

	#home h2 {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 30px;
	}
}

@media (max-height: 600px) {
	#home {
		height: 110vh;
	}
}
@media (min-width: 570px) and (max-width: 768px) {
	#home .btn-get-started {
		margin-top: 10px;
	}
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section {
	padding: 60px 0;
	overflow: hidden;
}

.section-bg {
	background-color: #f1f7fd;
}

.section-title {
	text-align: center;
	padding-bottom: 30px;
}

.section-title h2 {
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 20px;
	padding-bottom: 20px;
	position: relative;
	color: #e37e82;
}

.section-title h2::before {
	content: "";
	position: absolute;
	display: block;
	width: 120px;
	height: 1px;
	background: #ddd;
	bottom: 1px;
	left: calc(50% - 60px);
}

.section-title h2::after {
	content: "";
	position: absolute;
	display: block;
	width: 40px;
	height: 3px;
	background: rgb(44, 73, 100);
	bottom: 0;
	left: calc(50% - 20px);
}

.section-title p {
	margin-bottom: 0;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
	text-align: center;
	border: 1px solid #d5e1ed;
	padding: 80px 20px;
	transition: all ease-in-out 0.3s;
	width: 100%;
}

.services .icon-box .icon {
	margin: 0 auto;
	width: 64px;
	height: 64px;
	background: #11193d;
	border-radius: 5px;
	transition: all 0.3s ease-out 0s;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	transform-style: preserve-3d;
}

.services .icon-box .icon i {
	color: #fff;
	font-size: 28px;
}

.services .icon-box .icon::before {
	position: absolute;
	content: "";
	left: -8px;
	top: -8px;
	height: 100%;
	width: 100%;
	background: #d79da9;
	border-radius: 5px;
	transition: all 0.3s ease-out 0s;
	transform: translateZ(-1px);
}

.services .icon-box h4 {
	font-weight: 700;
	margin-bottom: 15px;
	font-size: 24px;
}

.services .icon-box h4 a {
	color: rgb(44, 73, 100);
}

.services .icon-box p {
	line-height: 24px;
	font-size: 14px;
	margin-bottom: 0;
}

.services .icon-box:hover {
	background: #e57e80;
	border-color: #d79da9;
}

.services .icon-box:hover .icon {
	background: #fff;
}

.services .icon-box:hover .icon i {
	color: #d79da9;
}

.services .icon-box:hover .icon::before {
	background: #d79da9;
}

.services .icon-box:hover h4 a,
.services .icon-box:hover p {
	color: #fff;
}

/*--------------------------------------------------------------
# Appointments
--------------------------------------------------------------*/
.appointment .php-email-form {
	width: 100%;
}

.appointment .php-email-form .form-group {
	padding-bottom: 8px;
}

.appointment .php-email-form .validate {
	display: none;
	color: red;
	margin: 0 0 15px 0;
	font-weight: 400;
	font-size: 13px;
}

.appointment .php-email-form .error-message {
	display: none;
	color: #fff;
	background: #ed3c0d;
	text-align: left;
	padding: 15px;
	font-weight: 600;
}

.appointment .php-email-form .error-message br + br {
	margin-top: 25px;
}

.appointment .php-email-form .sent-message {
	display: none;
	color: #fff;
	background: #18d26e;
	text-align: center;
	padding: 15px;
	font-weight: 600;
}

.appointment .php-email-form .loading {
	display: none;
	background: #fff;
	text-align: center;
	padding: 15px;
}

.appointment .php-email-form .loading:before {
	content: "";
	display: inline-block;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin: 0 10px -6px 0;
	border: 3px solid #18d26e;
	border-top-color: #eee;
	-webkit-animation: animate-loading 1s linear infinite;
	animation: animate-loading 1s linear infinite;
}

.appointment .php-email-form input,
.appointment .php-email-form textarea,
.appointment .php-email-form select {
	border-radius: 0;
	box-shadow: none;
	font-size: 14px;
	padding: 10px !important;
}

.appointment .php-email-form input:focus,
.appointment .php-email-form textarea:focus,
.appointment .php-email-form select:focus {
	border-color: #d79da9;
}

.appointment .php-email-form input,
.appointment .php-email-form select {
	height: 44px;
}

.appointment .php-email-form textarea {
	padding: 10px 12px;
}

.appointment .php-email-form button[type="submit"] {
	background: #d79da9;
	border: 0;
	padding: 10px 35px;
	color: #fff;
	transition: 0.4s;
	border-radius: 50px;
}

.appointment .php-email-form button[type="submit"]:hover {
	background: #1c84e3;
}

/*--------------------------------------------------------------
# Departments
--------------------------------------------------------------*/
.departments {
	overflow: hidden;
}

.departments .nav-tabs {
	border: 0;
}

.departments .nav-link {
	border: 0;
	padding: 12px 15px 12px 0;
	transition: 0.3s;
	color: #2c4964;
	border-radius: 0;
	border-right: 2px solid #ebf1f6;
	font-weight: 600;
	font-size: 15px;
}

.departments .nav-link:hover {
	color: #d79da9;
}

.departments .nav-link.active {
	color: #d79da9;
	border-color: #d79da9;
}

.departments .tab-pane.active {
	-webkit-animation: fadeIn 0.5s ease-out;
	animation: fadeIn 0.5s ease-out;
}

.departments .details h3 {
	font-size: 26px;
	font-weight: 600;
	margin-bottom: 20px;
	color: #2c4964;
}

.departments .details p {
	color: #777777;
}

.departments .details p:last-child {
	margin-bottom: 0;
}

@media (max-width: 992px) {
	.departments .nav-link {
		border: 0;
		padding: 15px;
	}

	.departments .nav-link.active {
		color: #fff;
		background: #d79da9;
	}
}

/*--------------------------------------------------------------
# Doctors
--------------------------------------------------------------*/
.doctors {
	background: #fff;
}

.doctors .member {
	position: relative;
	box-shadow: 0px 2px 15px rgba(44, 73, 100, 0.08);
	padding: 30px;
	border-radius: 10px;
}

.doctors .member .pic {
	overflow: hidden;
	width: 150px;
	border-radius: 50%;
}
.doctors .member .pic img {
	transition: ease-in-out 0.3s;
}

.doctors .member:hover img {
	transform: scale(1.1);
}

.doctors .member .member-info {
	padding-left: 30px;
}

.doctors .member h4 {
	font-weight: 700;
	margin-bottom: 5px;
	font-size: 20px;
	color: rgb(44, 73, 100);
}

.doctors .member span {
	display: block;
	font-size: 15px;
	padding-bottom: 10px;
	position: relative;
	font-weight: 500;
}

.doctors .member span::after {
	content: "";
	position: absolute;
	display: block;
	width: 50px;
	height: 1px;
	background: #b2c8dd;
	bottom: 0;
	left: 0;
}

.doctors .member p {
	margin: 10px 0 0 0;
	font-size: 14px;
}

.doctors .member .social {
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.doctors .member .social a {
	transition: ease-in-out 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	width: 32px;
	height: 32px;
	background: #d79da9;
}

.doctors .member .social a i {
	color: #fff;
	font-size: 16px;
	margin: 0 2px;
}

.doctors .member .social a:hover {
	background: #d79da9;
}

.doctors .member .social a + a {
	margin-left: 8px;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
	padding: 0 100px;
}

.faq .faq-list ul {
	padding: 0;
	list-style: none;
}

.faq .faq-list li + li {
	margin-top: 15px;
}

.faq .faq-list li {
	padding: 4px;
	background: #fff;
	border-radius: 4px;
	position: relative;
}

.faq .faq-list a {
	display: block;
	position: relative;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	padding: 0 30px;
	outline: none;
	cursor: pointer;
}

.faq .faq-list .icon-help {
	font-size: 24px;
	position: absolute;
	right: 0;
	left: 20px;
	color: #76b5ee;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
	font-size: 24px;
	position: absolute;
	right: 0;
	top: 0;
}

.faq .faq-list p {
	margin-bottom: 0;
	padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
	display: none;
}

.faq .faq-list a.collapsed {
	color: #343a40;
}

.faq .faq-list a.collapsed:hover {
	color: #d79da9;
}

.faq .faq-list a.collapsed .icon-show {
	display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
	display: none;
}

@media (max-width: 1200px) {
	.faq .faq-list {
		padding: 0;
	}
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
	width: 100%;
	padding: 10px;
}

.contact .info i {
	font-size: 20px;
	color: #d79da9;
	float: left;
	width: 44px;
	height: 44px;
	background: rgb(44, 73, 100);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	transition: all 0.3s ease-in-out;
}

.contact .info h4 {
	padding: 0 0 0 60px;
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 5px;
	color: rgb(44, 73, 100);
}

.contact .info p {
	padding: 0 0 0 60px;
	margin-bottom: 0;
	font-size: 14px;
	color: #4b7dab;
}

.contact .info .email,
.contact .info .phone {
	margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
	background: #e57e80;
	color: #fff;
}

.contact .php-email-form {
	width: 100%;
	background: #fff;
}

.contact .php-email-form .form-group {
	padding-bottom: 8px;
}

.contact .php-email-form .error-message {
	display: none;
	color: #fff;
	background: #ed3c0d;
	text-align: left;
	padding: 15px;
	font-weight: 600;
}

.contact .php-email-form .error-message br + br {
	margin-top: 25px;
}

.contact .php-email-form .sent-message {
	display: none;
	color: #fff;
	background: #18d26e;
	text-align: center;
	padding: 15px;
	font-weight: 600;
}

.contact .php-email-form .loading {
	display: none;
	background: #fff;
	text-align: center;
	padding: 15px;
}

.contact .php-email-form .loading:before {
	content: "";
	display: inline-block;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin: 0 10px -6px 0;
	border: 3px solid #18d26e;
	border-top-color: #eee;
	-webkit-animation: animate-loading 1s linear infinite;
	animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
	border-radius: 0;
	box-shadow: none;
	font-size: 14px;
}

.contact .php-email-form input {
	height: 44px;
}

.contact .php-email-form textarea {
	padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
	background: #d79da9;
	border: 0;
	padding: 10px 35px;
	color: #fff;
	transition: 0.4s;
	border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
	background: #1c84e3;
}

@-webkit-keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* marquee */
.divv {
	position: relative;
	margin-top: 10px;
	overflow: hidden;
}
.divvv {
	position: absolute;
	background-color: #f6f9ff;
	color: #e57e80;
	width: 101px;
	z-index: 1;
	font-size: 18px;
	height: 50px;
	padding-left: 10px;
}
.noticeText {
	font-weight: bold;
}
.spanani {
	position: relative;
	animation-name: example;
	animation-duration: 18s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
@keyframes example {
	0% {
		right: -1300px;
	}
	100% {
		right: 900px;
	}
}
.spanani:hover {
	animation-play-state: paused;
}

.btnfooter {
	background: rgb(44, 73, 100);
	height: 46px;
	border: none;
	border-radius: 50px;
	color: white;
	font-family: sora;
	width: 200px;
}
.btnfooter:hover {
	background-color: #e57e80;
}
.ab {
	content: "\f32f";
	color: rgb(44, 73, 100);
	float: right;
	margin-top: -40px;
}
.ax {
	background: none !important;
	border: 1px solid rgb(44, 73, 100) !important;
	color: rgb(44, 73, 100) !important;
}
.ax:hover {
	background-color: rgb(44, 73, 100) !important;
	color: white !important;
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
	.ab {
		margin-top: 0px;
		margin-right: 0px;
	}
}

/* -------------------------------------------------
   */

.loader-on {
	max-height: 100vh !important;
	overflow: hidden !important;
}

.loaderOuter {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.95);
	z-index: 20000;
	position: relative;
}

.loader {
	position: relative;
	width: 85px;
	height: 50px;
	background-repeat: no-repeat;
	background-image: linear-gradient(#fff 50px, transparent 0),
		linear-gradient(#fff 50px, transparent 0),
		linear-gradient(#fff 50px, transparent 0),
		linear-gradient(#fff 50px, transparent 0),
		linear-gradient(#fff 50px, transparent 0),
		linear-gradient(#fff 50px, transparent 0);
	background-position: 0px center, 15px center, 30px center, 45px center,
		60px center, 75px center, 90px center;
	animation: rikSpikeRoll 0.65s linear infinite alternate;
}
@keyframes rikSpikeRoll {
	0% {
		background-size: 10px 3px;
	}
	16% {
		background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px, 10px 3px;
	}
	33% {
		background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px,
			10px 3px;
	}
	50% {
		background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px,
			10px 3px;
	}
	66% {
		background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px,
			10px 3px;
	}
	83% {
		background-size: 10px 3px, 10px 3px, 10px 10px, 10px 30px, 10px 50px,
			10px 3px;
	}
	100% {
		background-size: 10px 3px, 10px 3px, 10px 3px, 10px 10px, 10px 30px,
			10px 50px;
	}
}
